import React, { Component } from 'react';
import { withRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Head from 'next/head';
import SlackWebHook from "../services/slack";
import FreeConsultationModal from '../components/FreeConsultationModal';
const Navigation = dynamic(() => import('../components/Navigation'));
const Footer = dynamic(() => import('../components/Footer'));

class Layout extends Component {
    constructor(props) {
        super(props);
        this.consultationModal = React.createRef();
    }

    componentDidMount() {
        if(this.props.router && this.props.router.asPath.includes('consultation=open')) {
            this.consultationModal.current.consultationModal.show();
        }
        setTimeout(() => {
            this.queryAppend();
        }, 100);

        window.onpopstate = function (e) {
            document.querySelector('body').classList?.remove("modal-open");
            document.querySelector('.modal-backdrop')?.classList.remove("modal-backdrop");
            document.querySelector('body').style = '';
        };

        try {
            if(process.env.NEXT_PUBLIC_ENV === 'production'){
                document.getElementById('pageurl').value = window.location.href;
            }
          } catch (e) {
            console.log(e);
          }

    }

    componentDidCatch(error, info) {
        try {
            SlackWebHook.sendSlackMessage(
                `Layout-${error.message}-${JSON.stringify(info)}-${JSON.stringify(
                    location
                )}`,
                process.env.NEXT_PUBLIC_SLACK_USERNAME,
                ":scream:",
                process.env.NEXT_PUBLIC_SLACK_ERROR_CHANNEL
            );
        } catch (e) {
            console.log(e);
        }
    }

    queryAppend = () => {
        if (sessionStorage.getItem("gclid") || sessionStorage.getItem("fbclid")) {
            let query = location.search.replace("?", "").replace("&", "=").split("=");
            if (!query.includes("gclid") && !query.includes("fbclid")) {
                let search = location.search === "" ? "?" : "";
                if (sessionStorage.getItem("gclid"))
                    search +=
                        search === "?"
                            ? sessionStorage.getItem("gclid") &&
                            "gclid=" + sessionStorage.getItem("gclid")
                            : sessionStorage.getItem("gclid") &&
                            "&gclid=" + sessionStorage.getItem("gclid");
                if (sessionStorage.getItem("fbclid"))
                    search +=
                        search === "?"
                            ? sessionStorage.getItem("fbclid") &&
                            "fbclid=" + sessionStorage.getItem("fbclid")
                            : sessionStorage.getItem("fbclid") &&
                            "&fbclid=" + sessionStorage.getItem("fbclid");
                const href = `${location.href}${search}`;
                if (sessionStorage.getItem("path") !== location.pathname) {
                    window.history.pushState(null, "", href);
                    sessionStorage.setItem("path", location.pathname);
                }
            }
        } else {
            let search = location.search
                .replace("?", "")
                .replace("&", "=")
                .split("=");
            if (search.length > 0) {
                if (search.includes("gclid")) {
                    const gci = search.findIndex((el) => el === "gclid");
                    sessionStorage.setItem("gclid", search[gci + 1]);
                }
                if (search.includes("fbclid")) {
                    const fbi = search.findIndex((el) => el === "fbclid");
                    sessionStorage.setItem("fbclid", search[fbi + 1]);
                }
            }
        }
    };

    render() {
        return (
            <div className='layout'>
                <Head>
                    <title>Collegify | College Admissions Consulting</title>
                    <meta name="google-site-verification" content="3hR3uCt9k8IJoHUHtc5aMy_nfLFRhwIZ5VyqjPRdrCw" />

                    <script defer src="https://checkout.razorpay.com/v1/checkout.js"/>

                    {
                        process.env.NEXT_PUBLIC_ENV === 'production' &&
                        <>
                            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-109424464-2"/>
                            <script async type='text/javascript' src="/js/gads.js" />
                            <script dangerouslySetInnerHTML={{__html:'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
                                    'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
                                    'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
                                    '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
                                    '})(window,document,\'script\',\'dataLayer\',\'GTM-KW5TD2D\')'}} />

                            <script src="https://www.googletagmanager.com/gtag/js?id=AW-975912079"/>
                            <script src="/js/gads2.js" />
                        </>
                    }
                </Head>

                {
                    process.env.NEXT_PUBLIC_ENV === 'production' &&
                    <>
                        <noscript>
                            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KW5TD2D" height="0" width="0" style={{display:"none",visibility:"hidden"}} />
                        </noscript>
                    </>
                }

                <Navigation />
                <main>
                    { this.props.children }
                </main>
                <Footer />
                <FreeConsultationModal ref={this.consultationModal} />
            </div>
        );
    }
}

export default withRouter(Layout);
