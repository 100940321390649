import React, { Component } from 'react';
import Layout from '../layouts/Layout';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import dynamic from 'next/dynamic'
// import SiteLoader from '../components/SiteLoader'
import FreeConsultationModal from '../components/FreeConsultationModal';
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward'
import { MdAccountBalance } from '@react-icons/all-files/md/MdAccountBalance'
import { MdSchool } from '@react-icons/all-files/md/MdSchool'
import { MdLocalLibrary } from '@react-icons/all-files/md/MdLocalLibrary'
import { MdStar } from '@react-icons/all-files/md/MdStar'
const WhiteCurveSVG = dynamic(() => import('../components/WhiteCurveSVG'));
const CareerMappingCTA = dynamic(() => import('../components/CareerMappingCTA'));
const CustomImage = dynamic(() => import('../components/CustomImage'));

export default class AdmissionsLanding extends Component {

    state = {
        service: 'ug'
    }

    componentDidMount() {
        document.querySelector('body').classList.add('overflow-toggle');
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('overflow-toggle');
    }


    selectService = (service) => {
        this.setState({ service })
    }

    render() {
        const { service } = this.state;

        return (
            <Layout>
                {/* <SiteLoader /> */}
                {
                    process.env.NEXT_PUBLIC_ENV === 'production' &&
                    <NextSeo
                        title='College Admissions Consulting/Counselor - Study Abroad'
                        titleTemplate='Collegify | %s'
                        copyright='Collegify has copyrights to all the content on the page.'
                        keywords='dream college,experienced mentors, college applications, essays,best fit university, strong alumni network, undergraduate, postgraduate, mba, classroom coaching, uc berkeley,university of cambridge, carnegie mellon university, cornell, yale, harvard,lse,upenn,nyu, oxford, ucla, stanford university, nus, scholarships, financial aid'
                        description='Collegify began as a Bespoke Education Services Company specializing in Admissions Consulting, Career Guidance and Test Preparation Services in 2011. Over the past 10 years, our alumni network has grown across the globe.'
                        openGraph={{
                            url: process.env.NEXT_PUBLIC_APP_URI,
                            title: 'Collegify | College Admissions Consulting/Counselor - Study Abroad',
                            type: 'website',
                            description: 'Collegify began as a Bespoke Education Services Company specializing in Admissions Consulting, Career Guidance and Test Preparation Services in 2011. Over the past 10 years, our alumni network has grown across the globe.',
                            images: [
                                {
                                    url: process.env.NEXT_PUBLIC_FILE_URI + 'social-banner/admission-landing.jpg',
                                    width: 500,
                                    height: 500,
                                    alt: 'A Holistic Approach to College Admissions',
                                },
                            ],
                            site_name: 'Collegify',
                        }}
                    />
                }

                <div id="admissions">

                    {/* Admissions Intro Section */}
                    <section className="nav-margin admissions-landing-intro-section hero-bg">
                        <div className="text-center py-3 px-3 px-md-0">
                            <h1 className="mb-5">
                                Get accepted into the <br className="d-none d-md-block" />
                                college of your dreams.
                            </h1>

                            <div className="ticker-container">
                                <div className="ticker flex-row gap-3ch mt-3 mb-5">
                                    {[...Array(2).fill(1).map((val, i) => (
                                        <React.Fragment key={i}>
                                            <CustomImage srcSetPaths={[{path: '/university-logos/berkeley.svg?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/berkeley.svg?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/berkeley.svg?tr=h-40,f-png" alt="Berkeley Logo" height="40px" width="131px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/harvard.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/harvard.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/harvard.png?tr=h-40,f-png" alt="Harvard Logo" height="40px" width="158px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/stanford.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/stanford.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/stanford.png?tr=h-40,f-png" alt="Stanford Logo" height="40px" width="123px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/princeton.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/princeton.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/princeton.png?tr=h-40,f-png" alt="Princeton Logo" height="40px" width="145px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/cambridge.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/cambridge.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/cambridge.png?tr=h-40,f-png" alt="Cambridge Logo" height="40px" width="193px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/oxford.svg?tr=w-137,f-avif', type: 'image/avif'},{path: '/university-logos/oxford.svg?tr=w-137,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/oxford.svg?tr=w-137,f-png" alt="Oxford Logo" height="auto" width="137px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/columbia-university.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/columbia-university.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/columbia-university.png?tr=h-40,f-png" alt="columbia Logo" height="40px" width="260px" imgCDN />

                                            <CustomImage srcSetPaths={[{path: '/university-logos/cornell.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/cornell.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/cornell.png?tr=h-40,f-png" alt="cornell Logo" height="40px" width="179px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/duke.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/duke.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/duke.png?tr=h-40,f-png" alt="duke Logo" height="40px" width="112px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/imperial.svg?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/imperial.svg?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/imperial.svg?tr=h-40,f-png" alt="imperial Logo" height="40px" width="153px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/lbs.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/lbs.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/lbs.png?tr=h-40,f-png" alt="lbs Logo" height="40px" width="40px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/nyu.svg?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/nyu.svg?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/nyu.svg?tr=h-40,f-png" alt="nyu Logo" height="40px" width="117px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/penn.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/penn.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/penn.png?tr=h-40,f-png" alt="penn Logo" height="40px" width="123px" imgCDN />
                                            <CustomImage srcSetPaths={[{path: '/university-logos/vanderbilt.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/vanderbilt.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/vanderbilt.png?tr=h-40,f-png" alt="vanderbilt Logo" height="40px" width="218px" imgCDN />

                                        </React.Fragment>
                                    ))]}
                                </div>
                            </div>

                            <p className="mb-4">100+ universities to choose from, guided by our experts.</p>

                            <div className="flex-center">

                                        <button className="btn btn-primary text-white btn-with-icon" data-bs-toggle="modal" data-bs-target="#freeConsultationModal">
                                            Book A Consultation
                                            <span className="btn-icon"><MdArrowForward className="material-icons" /></span>
                                        </button>

                            </div>
                        </div>
                        <WhiteCurveSVG />
                    </section>
                    {/* <FreeConsultationModal /> */}

                    {/* Admissions Testimonial Cards */}
                    <section className="mb-5">
                        <div className="container">
                            <div className="row align-items-end justify-content-between">
                                <div className="col-md-12 col-lg-4">
                                    <div className="card avatar-card border-primary mb-4">
                                        <div className="avatar">
                                            <CustomImage srcSetPaths={[{path: '/students/tarang-singhal.png?tr=w-92,f-avif', type: 'image/avif'},{path: '/students/tarang-singhal.png?tr=w-92,f-auto', type: 'image/webp'}]}
                                                mainSrc="/students/tarang-singhal.png?tr=w-92,f-png" alt="Tarang Singhal" width="92px" height="92px" imgCDN />
                                            {/* <img src="/students/tarang-singhal.png" alt="Tarang Singhal" width="92px" height="92px" /> */}
                                        </div>
                                        <div className="card-body text-center">
                                            <h5>Tarang S.</h5>
                                            <p>I can&apos;t thank Collegify enough for their support and guidance. I will be joining Columbia for the MPA program this fall!</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <CustomImage srcSetPaths={[{path: '/university-logos/columbia-university.png?tr=h-35,f-avif', type: 'image/avif'},{path: '/university-logos/columbia-university.png?tr=h-35,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/columbia-university.png?tr=h-35,f-png" className="mx-auto" alt="Columbia College Logo" height="35px" width="235px" imgCDN />
                                        {/* <img width="168px" height="auto" className='mx-auto' src="/images/university-logos/columbia.png" alt="Columbia College Logo"/> */}
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4 order-1 order-lg-0">
                                    <div className="mb-5">
                                        <div className="card avatar-card border-primary mb-4">
                                            <div className="avatar">
                                            <CustomImage srcSetPaths={[{path: '/students/kinni-shah.png?tr=w-92,f-avif', type: 'image/avif'},{path: '/students/kinni-shah.png?tr=w-92,f-auto', type: 'image/webp'}]}
                                                mainSrc="/students/kinni-shah.png?tr=w-92,f-png" alt="kinni shah" width="92px" height="92px" imgCDN />
                                                {/* <img src="/images/admissions/kinni-shah.png" alt="Student Image" width="92px" height="92px"  /> */}
                                            </div>
                                            <div className="card-body text-center">
                                                <h5>Kinni S.</h5>
                                                <p>My application gained me acceptance into NYU and Cornell MBA programs, a feat that wouldn&apos;t have been possible without Collegify!</p>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <CustomImage srcSetPaths={[{path: '/university-logos/nyu.svg?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/nyu.svg?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/nyu.svg?tr=h-40,f-png" className="mx-auto" alt="NYU Logo" width="118px" height="40px" imgCDN />
                                            {/* <img width="100px" height="auto" className='mx-auto' src="/images/university-logos/nyu.svg" alt="NYU Logo" /> */}
                                        </div>
                                    </div>
                                    <div className="flex-center py-4">
                                        <Link href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI + "/testimonials"}>
                                            <a className="inline-link">See More Testimonials</a>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4">
                                    <div className="card avatar-card border-primary mb-4">
                                        <div className="avatar">
                                            <CustomImage srcSetPaths={[{path: '/students/mihika-goenka.png?tr=w-92,f-avif', type: 'image/avif'},{path: '/students/mihika-goenka.png?tr=w-92,f-auto', type: 'image/webp'}]}
                                                    mainSrc="/students/mihika-goenka.png?tr=w-92,f-png" alt="mihika goenka" width="92px" height="92px" imgCDN />
                                            {/* <img src="/images/admissions/mihika-goenka.png" alt="Student Image" width="92px" height="92px"  /> */}
                                        </div>
                                        <div className="card-body text-center">
                                            <h5>Mihikaa G.</h5>
                                            <p>Collegify was able to understand my needs and helped me prioritize a list of colleges that would be a good fit for me. I finally picked Cornell for computer science.</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                    <CustomImage srcSetPaths={[{path: '/university-logos/cornell.png?tr=h-40,f-avif', type: 'image/avif'},{path: '/university-logos/cornell.png?tr=h-40,f-auto', type: 'image/webp'}]}
                                                mainSrc="/university-logos/cornell.png?tr=h-40,f-png" className="mx-auto" alt="Cornell Logo" width="180px" height="40px" imgCDN />
                                        {/* <img width="160px" height="auto" className='mx-auto' src="/images/university-logos/cornell.png" alt="Cornell College Logo" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="py-5">
                        <div className="container">
                            <div className="row justify-content-center mb-5">
                                <div className="col-md-10">
                                    <p className="h4 text-center">Since 2011, over 7500 Collegify students have been placed at some of the world’s best universities, some of whom have received over $40 million in scholarships and financial aid.</p>
                                </div>
                            </div>

                            <div className="flex-center">
                                <Link href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI + "/hallOffame"}>
                                    <button className="btn btn-secondary btn-with-icon bg-white">
                                        <span className="btn-icon btn-icon-left bg-yellow"><MdStar /></span>
                                        Our Hall of Fame
                                    </button>
                                </Link>
                            </div>
                        </div>

                    </section>

                    {/* Desktop - What We Offer */}
                    <section className="what-we-offer--mobile d-none d-lg-block py-5">
                        <div className="flex-center mb-5">
                            <h3 className="text-uppercase mb-0 styled-heading">What We Offer</h3>
                        </div>

                        {/* Services */}
                        <div className="container">
                            <div className="flex-center flex-wrap mb-5">
                                <div className="text-center mb-5 mb-lg-0">
                                    <button aria-label="UG Service" onClick={() => this.selectService('ug')}
                                        className={`light-blue-icon mx-5 mb-3 ${service === 'ug' ? 'active' : ''}`}>
                                        <MdLocalLibrary />
                                    </button>
                                    <h5>Undergraduate</h5>
                                </div>
                                <div className="text-center mb-5 mb-lg-0">
                                    <button aria-label="PG Service" onClick={() => this.selectService('pg')}
                                    className={`light-blue-icon mx-5 mb-3 ${service === 'pg' ? 'active' : ''}`}>
                                        <MdSchool />
                                    </button>
                                    <h5>Postgraduate</h5>
                                </div>
                                <div className="text-center mb-5 mb-lg-0">
                                    <button aria-label="MBA Service"
                                        className={`light-blue-icon mx-5 mb-3 ${service === 'mba' ? 'active' : ''}`}
                                        onClick={() => this.selectService('mba')}>
                                            <MdAccountBalance />
                                    </button>
                                    <h5>MBA</h5>
                                </div>
                                {/* <div className="text-center mb-5 mb-lg-0">
                                    <a className="pointer" onClick={() => this.selectService('cc')}>
                                        <div
                                            className={`light-blue-icon mx-5 mb-3 ${service === 'cc' ? 'active' : ''}`}>
                                            <MdGroupAdd className="material-icons" />
                                        </div>
                                    </a>
                                    <h5>Classroom Coaching</h5>
                                </div> */}
                            </div>
                        </div>

                        {/* Testimonial Card */}
                        <div className="container">
                            <div className="card" style={{minHeight: 124}}>
                                <div className="card-body px-5">
                                    <div className="flex-row align-items-center">
                                    <CustomImage srcSetPaths={[{path: '/common/quote-tint-start.svg?tr=w-60,f-avif', type: 'image/avif'},{path: '/common/quote-tint-start.svg?tr=w-60,f-auto', type: 'image/webp'}]}
                                            mainSrc="/common/quote-tint-start.svg?tr=w-60,f-png" alt="Quote Start" width="60px" height="48px" imgCDN className="d-none d-lg-block me-3" />
                                        <div className="flex-row gap-2ch mx-0 mx-md-4">
                                            <div className="university-logo-container flex-center flex-shrink-0">
                                                { service === 'ug' &&
                                                    <CustomImage srcSetPaths={[{path: '/university-logos/harvard.png?tr=w-90,f-avif', type: 'image/avif'},{path: '/university-logos/harvard.png?tr=w-90,f-auto', type: 'image/webp'}]}
                                                        mainSrc="/university-logos/harvard.png?tr=w-90,f-png" alt="Harvard Logo" height="auto" width="90px"  imgCDN />}
                                                {/* <img width="90px" height="auto" src="/images/university-logos/harvard.png"  alt="University of Pennsylvania Logo" />  */}
                                                { service === 'pg' &&
                                                    <CustomImage srcSetPaths={[{path: '/university-logos/lbs.png?tr=w-90,f-avif', type: 'image/avif'},{path: '/university-logos/lbs.png?tr=w-90,f-auto', type: 'image/webp'}]}
                                                        mainSrc="/university-logos/lbs.png?tr=w-90,f-png" alt="London Business School Logo" height="60px" width="90px" imgCDN />}
                                                {/* <img width='90px' height="60px" src="/images/university-logos/lbs.png" alt="London Business School Logo" /> */}
                                                { service === 'mba' &&
                                                    <CustomImage srcSetPaths={[{path: '/university-logos/nus.jpg?tr=w-90,f-avif', type: 'image/avif'},{path: '/university-logos/nus.jpg?tr=w-90,f-auto', type: 'image/webp'}]}
                                                        mainSrc="/university-logos/nus.jpg?tr=w-90,f-png" alt="London Business School Logo" height="auto" width="90px"  imgCDN />}
                                                {/* <img width="90px" height="auto" src="/images/university-logos/nus.jpg" alt="NUS Logo" /> */}
                                                { service === 'cc' &&
                                                    <div className="flex-column">
                                                        <h3 className="font-weight-bold text-success">SAT</h3>
                                                        <h2 className="font-weight-bold text-success">1560</h2>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                service === 'ug' &&
                                                <div className="flex-grow-1">
                                                    <p className="mb-2">Despite my hectic schedule, the Collegify mentors were more than willing to work around my timetable. I am grateful for the personalised attention!</p>
                                                    <h5>Shreyvardhan S.</h5>
                                                </div>
                                            }
                                            {
                                                service === 'pg' &&
                                                <div className="flex-grow-1">
                                                    <p className="mb-2">It has been truly a wonderful experience working with Collegify. The entire team has been instrumental in my success.</p>
                                                    <h5>Pramod N.</h5>
                                                </div>
                                            }
                                            {
                                                service === 'mba' &&
                                                <div className="flex-grow-1">
                                                    <p className="mb-2">Collegify’s personalized approach to the essay ideation process helped me find my best fit college based on my profile.</p>
                                                    <h5>Nakul J.</h5>
                                                </div>
                                            }
                                            {/* {
                                                service === 'cc' &&
                                                <div className="flex-grow-1">
                                                    <p className="mb-2">Collegify has been an essential part of my SAT journey. They organized flexible schedules, created tailor-made practice sets, and paid individual attention to my needs.</p>
                                                    <h5>Akshat K.</h5>
                                                </div>
                                            } */}
                                        </div>
                                        <CustomImage srcSetPaths={[{path: '/common/quote-tint-end.svg?tr=w-60,f-avif', type: 'image/avif'},{path: '/common/quote-tint-end.svg?tr=w-60,f-auto', type: 'image/webp'}]}
                                            mainSrc="/common/quote-tint-end.svg?tr=w-60,f-png" alt="Quote end" width="60px" height="48px" imgCDN className="d-none d-lg-block ms-3" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Description */}
                        <div className="service-description">
                            <img src="/images/curve-light-yellow-top.svg" width='100%' height="80px" alt="Yellow Curve"/>
                            {
                                service === 'ug' &&
                                <div className="service-description-inner bg-primary-light">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <p className="mb-4 text-center">An Early Bird or a Late Bloomer, Collegify’s Bespoke Mentoring Services are equipped to cater to students at any stage of the College Application process.</p>
                                                <Link href="/undergraduate">
                                                    <button className="btn btn-warning bg-white btn-with-icon btn-text-revert mx-auto">
                                                        Undergraduate Services
                                                        <span className="btn-icon bg-yellow"><MdArrowForward className="material-icons" /></span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                service === 'pg' &&
                                <div className="service-description-inner bg-primary-light">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <p className="mb-4 text-center">Collegify’s PG Admissions Consulting Services are ideal for students looking to apply to specialized graduate courses or doctoral degrees at elite universities.</p>
                                                <Link href="/postgraduate">
                                                    <button className="btn btn-warning bg-white btn-with-icon btn-text-revert mx-auto">
                                                        Postgraduate Services
                                                        <span className="btn-icon bg-yellow"><MdArrowForward className="material-icons" /></span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                service === 'mba' &&
                                <div className="service-description-inner bg-primary-light">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <p className="mb-4 text-center">Collegify’s team of MBA Admissions Consultants specialize in guiding aspiring MBA applicants through challenging B-School applications.</p>
                                                <Link href="/mba">
                                                    <button className="btn btn-warning bg-white btn-with-icon btn-text-revert mx-auto">
                                                        MBA Services
                                                        <span className="btn-icon bg-yellow"><MdArrowForward className="material-icons" /></span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* {
                                service === 'cc' &&
                                <div className="service-description-inner bg-primary-light">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <p className="mb-4 text-center">Our comprehensive Test Prep Services enable our students to ace Standardized Tests, not just with comprehension and practice, but all-encompassing, immersive learning opportunities.</p>
                                                <Link href="/coaching">
                                                    <button className="btn btn-warning bg-white btn-with-icon btn-text-revert mx-auto">
                                                        Classroom Coaching
                                                        <span className="btn-icon bg-yellow"><MdArrowForward className="material-icons" /></span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } */}
                            <img src="/images/curve-light-yellow-bottom.svg" width='100%'  height="80px" alt="Yellow Curve" />
                        </div>

                    </section>

                    {/* Mobile - What We Offer */}
                    <section className="what-we-offer--mobile d-block d-lg-none py-4">
                        <div className="container">

                            <div className="flex-center mb-5">
                                <h3 className="text-uppercase fs-3 styled-heading">What We Offer</h3>
                            </div>
                            <div className="mb-5">
                                <div className="mobile-service-icon light-blue-icon mb-3 mx-auto"><MdLocalLibrary className="material-icons" /></div>
                                <h4 className="text-center mb-3">Undergraduate Admissions</h4>
                                <div className="card border-primary mb-4">
                                    <div className="card-body">
                                        <div className="flex-row align-items-center">

                                            <div className="flex-grow-1">
                                                <p className="mb-2">Despite my hectic schedule, the Collegify mentors were more than willing to work around my timetable. I am grateful for the personalised attention!</p>
                                                <h5>Shreyvardhan S. - Harvard University</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-4 text-center">An Early Bird or a Late Bloomer, Collegify’s Bespoke Mentoring Services are equipped to cater to students at any stage of the College Application process.</p>
                                <div className="flex-center">
                                    <Link href="/undergraduate">
                                        <a className="flex-row inline-link align-items-center">
                                            Undergraduate Services<MdArrowForward className="material-icons ms-2" />
                                        </a>
                                    </Link>
                                </div>
                            </div>

                            <div className="mb-5">
                                <div className="mobile-service-icon light-blue-icon mb-3 mx-auto"><MdSchool className="material-icons" /></div>
                                <h4 className="text-center mb-3">Postgraduate Admissions</h4>
                                <div className="card border-primary mb-4">
                                    <div className="card-body">
                                        <div className="flex-row align-items-center">

                                            <div className="flex-grow-1">
                                                <p className="mb-2">It has been truly a wonderful experience working with Collegify. The entire team has been instrumental in my success.</p>
                                                <h5>Pramod N. - London Business School</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-4 text-center">Collegify’s PG Admissions Consulting Services are ideal for students looking to apply to specialized graduate courses or doctoral degrees at elite universities.</p>
                                <div className="flex-center">
                                    <Link href="/postgraduate">
                                        <a className="flex-row inline-link align-items-center">
                                            Postgraduate Services<MdArrowForward className="material-icons ms-2" />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="mb-5">
                                <div className="mobile-service-icon light-blue-icon mb-3 mx-auto"><MdAccountBalance className="material-icons" /></div>
                                <h4 className="text-center mb-3">MBA Admissions</h4>
                                <div className="card border-primary mb-4">
                                    <div className="card-body">
                                        <div className="flex-row align-items-center">

                                            <div className="flex-grow-1">
                                                <p className="mb-2">Collegify’s personalized approach to the essay ideation process helped me find my best fit college based on my profile.</p>
                                                <h5>Nakul J. - National University of Singapore</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-4 text-center">Collegify’s team of MBA Admissions Consultants specialize in guiding aspiring MBA applicants through challenging B-School applications.</p>
                                <div className="flex-center">
                                    <Link href="/mba">
                                        <a className="flex-row inline-link align-items-center">
                                            MBA Services<MdArrowForward className="material-icons ms-2" />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Why Collegify */}
                    <section className="py-5">
                        <div className="container">
                            {/* Desktop */}
                            <div className="d-none d-sm-flex flex-center flex-wrap mb-5 horizontal-timeline-container admissions-landing-timeline">
                                <div className="flex-column m-4">
                                    <h5 className="mb-4">Individualized Mentoring</h5>
                                    <div className="yellow-circle"></div>
                                </div>
                                <div className="flex-column m-4">
                                    <h5 className="mb-4">Profile Building</h5>
                                    <div className="yellow-circle"></div>
                                </div>
                                <div className="flex-column m-4">
                                    <h5 className="mb-4">Best-fit Universities</h5>
                                    <div className="yellow-circle"></div>
                                </div>

                                <div className="flex-column m-4">
                                    <h5 className="mb-4">Essay &amp; Interview Prep</h5>
                                    <div className="yellow-circle"></div>
                                </div>
                                <div className="flex-column m-4">
                                    <h5 className="mb-4">Successful Admission!</h5>
                                    <div className="yellow-circle active"></div>
                                </div>
                            </div>

                            {/* Mobile */}
                            <div className="flex-center mb-5">
                                <div className="why-collegify d-block d-sm-none admissions-inner-nav">
                                    <div className="admissions-inner-nav-item"><h5>Individualized Mentoring</h5></div>
                                    <div className="admissions-inner-nav-item"><h5>Profile Building</h5></div>
                                    <div className="admissions-inner-nav-item"><h5>Best Fit Universities</h5></div>
                                    <div className="admissions-inner-nav-item"><h5>Essay &amp; Interview Prep</h5></div>
                                    <div className="admissions-inner-nav-item active"><h5>Successfull Admission!</h5></div>
                                </div>
                            </div>
                            <div className="text-center  mb-5">
                                <h3 className="fs-3 mb-2">Come aboard the collegify experience,</h3>
                                <h5 className="text-center font-weight-light">and get accepted into the college of your dreams!</h5>
                            </div>
                            <div className="flex-center">

                                        <button className="btn btn-primary text-white btn-lg" data-bs-toggle="modal" data-bs-target="#freeConsultationModal" >
                                            Book a Consultation Now
                                        </button>

                            </div>
                        </div>
                    </section>
                    <CareerMappingCTA />

                </div>

            </Layout>
        )
    }

}
